<template>
  <div class="container">
    <el-row>
      <el-col :span="4">
        <div style="height: 14.61rem;background: #fff;">
          <el-menu :default-active="active" class="el-menu-vertical-demo" @select="select">
            <el-menu-item index="1">
              <span slot="title">个人中心</span>
            </el-menu-item>
          </el-menu>
        </div>
      </el-col>

      <el-col :span="20">
        <div style="margin-left: 5px;">
          <about-us></about-us>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import AboutUs from './components/AboutUs.vue';
export default {
  components: {
    AboutUs
  },
  data() {
    return {
      active: '1',
    };
  },
  methods: {
    select(e) {
      this.active = e
    },
  }
}; 
</script>
<style scoped>
v-deep.is-active {
  color: #fff;
  background: #409EFF;
}
</style>