<template>
  <div class="bg">
    <!-- <div class="logo"><img src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg" /></div> -->

    <div style="padding-top: 20px">
      <el-row style="">
        <el-col :span="12" offset="1">
          <div class="box">
            <img src="../../../assets/img/myBg.jpg" alt="" />
            <div class="info">
              <div class="logo">
                <img :src="this.info.face" alt="" />
              </div>
              <div class="title">{{ this.info.name }}</div>
              <div class="timeBox">软件到期时间：{{ this.info.use_time }}</div>
              <div class="timeBox">视频合成时长{{ this.info.come_time }}秒</div>
              <div class="tips">注：时长不足请联系客服增加</div>
              <div class="status">高级版用户</div>
              <div style="text-align: right">
                <el-button type="danger" @click="logout">退出登录</el-button>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: "测试用户123",
      craft: "3680",
      time: "2023/9/30",
      url: "https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png",
      info: {},
    };
  },
  mounted() {
    // 渲染视频
    this.info = JSON.parse(localStorage.getItem("userInfo"));
    console.log(this.info);
    // console.log(this.$store.state.mInterval);

    let timer = setInterval(() => {
        console.log(1234567);
      }, 1000);
      console.log(1,timer);

      setTimeout(() => {
        clearInterval(timer);
      }, 4000);
  },
  methods: {
    logout() {
      console.log(this.$store.state.mInterval);
      clearInterval(this.$store.state.mInterval);
      localStorage.clear();
      this.$router.push({ name: "login" });
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  background: #fff;
  margin: 0.4rem 0;
  margin-bottom: 0;
  width: 38vw;
  height: 78vh;
  border-radius: 9px;

  img {
    width: 100%;
    height: 4.2rem;
  }

  .info {
    text-align: left;
    padding: 45px 20px 20px;
    font-size: 15px;

    .logo {
      background: #fff;
      border-radius: 2rem;
      position: absolute;
      left: 7%;
      top: 26%;
      padding: 5px;

      img {
        width: 2.1rem;
        height: 2.1rem;
        border-radius: 50px;
      }
    }

    .title {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 30px;
    }

    .timeBox {
      background: #f3f3f3;
      height: 1rem;
      line-height: 1rem;
      text-align: center;
      margin-bottom: 20px;
      border-radius: 8px;
    }

    .status {
      padding: 0.1rem 0.28rem;
      border: 1px solid #366ef4;
      border-radius: 6px;
      width: 2.13rem;
      position: absolute;
      top: 34vh;
      left: 31vw;
    }

    .tips {
      position: relative;
      top: 70px;
      left: 2.36rem;
      color: #808080;
      font-size: 14px;
    }
  }
}

::v-deep {
  p {
    max-width: 100%;
    text-align: left;
    text-indent: 24px;
    font-size: 16px;
  }

  .text {
    padding: 30px;
    padding-top: 0px;
  }
}
</style>
